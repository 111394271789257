import React from "react";
import { api } from "../../../services/api";
import { IMaskInput } from "react-imask";
import Alert from "../../Alert";
import Loader from "../../Loader";


const ImportStudent = () => {
  const [avas, setAvas] = React.useState([]);
  const [programs, setPrograms] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [selectedCPF, setSelectedCPF] = React.useState("");
  const [selectedAVA, setSelectedAVA] = React.useState(undefined);
  const [selectedCourse, setSelectedCourse] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);

  const [alert, setAlert] = React.useState({
    typeAlert: "",
    messageAlert: "",
  });
  const [alertIsVisible, setAlertIsVisible] = React.useState(false);

  async function listAvas() {
    //console.log("entrou")
    try {
      const response = await api.get("/ava");
      //console.log("avas, ",response)

      setAvas(response.data.avas);

      if (response.data.avas.length > 0){
        setSelectedAVA(response.data.avas[0].id_ava);
        listPrograms(response.data.avas[0].id_ava);
      }else{
        setSelectedAVA(undefined);
        listPrograms(undefined);
      }
      
    } catch (error) {
      setAlert({
        typeAlert: "error",
        messageAlert: "Não foi possível carregar a lista de Avas",
      });

      setAlertIsVisible(true);
    }
  }

  async function listPrograms(ava){
    //console.log("entrou2", ava)
    if (ava !== undefined){
      //console.log("entrou3")
      try{
          const response = await api.get(`/program/list/${parseInt(ava)}`);
          //console.log("programs", response)
          setPrograms([{id_program: 0, Modality:{name_modality: "Selecione um programa"}}, ...response.data.programs]);
          
      }catch (error){
        setAlert({
          typeAlert: "error",
          messageAlert: "Não foi possível carregar a lista de programas",
        });
  
        setAlertIsVisible(true);
      }
    }
    else{
      setPrograms([]);
    }
    setSelectedCourse(undefined)
    setCourses([]);
  }

  async function listCourses(program){
    //console.log("c entrou2", program)
    if (program !== undefined && program !== 0){
      //console.log("c entrou3")
      try{
          const response = await api.get(`/course/list/${parseInt(program)}`);
          //console.log("cursos", response)
          setCourses([{id_course: 0, code_ava_course: undefined, Modality:{name_modality: "Selecione um curso"}}, ...response.data.courses]);
          setSelectedCourse(undefined);
          
      }catch (error){
        setAlert({
          typeAlert: "error",
          messageAlert: "Não foi possível carregar a lista de cursos",
        });
  
        setAlertIsVisible(true);
      }
    }else{
      setCourses([]);
      setSelectedCourse(undefined);
    }
  }

  async function onChangeAVA(value) {
    setIsLoading(true);
    setSelectedAVA(value);
    await listPrograms(value);
    setIsLoading(false);
  }

  async function onChangeProgram(value) {
    setIsLoading(true);
    //console.log("onchange p: ", value)
    await listCourses(value);
    setIsLoading(false);
  }

  async function importStudent() {
    setIsLoading(true);
    setAlertIsVisible(false);

    try {
      if (selectedAVA !== undefined && selectedCourse !== undefined && !isNaN(selectedCourse) && selectedCPF !== ""){
        const response = await api.post("student/search_by_ava_and_cpf_moodle", {
          cpf_student: selectedCPF,
          id_ava: selectedAVA,
        });

        const student = response.data.student;
        //console.log("student: ", student)

        if (student.length < 1){
          setAlert({
            typeAlert: "error",
            messageAlert: "CPF não encontrado no AVA escolhido!",
          });
          setAlertIsVisible(true);
        }else if(student.length > 1){
          setAlert({
            typeAlert: "error",
            messageAlert: "CPF duplicado no AVA escolhido!",
          });
          setAlertIsVisible(true);
        }else{
          //console.log("importação: ", selectedAVA, selectedCourse, student[0].code_ava_student)

          const responseImport = await api.post(
            `import/mtur/student/${selectedAVA}/${selectedCourse}/${student[0].code_ava_student}`
          );
          //console.log(responseImport)
          setAlert({
            typeAlert:
              responseImport.data.error
                ? "error"
                : "success",
            messageAlert: responseImport.data.msg,
          });
    
          setAlertIsVisible(true);

        }

      }else{
        setAlert({
          typeAlert: "error",
          messageAlert: "Preencha todos os campos!",
        });
        setAlertIsVisible(true);
      }
    } catch (e) {
      setAlert({
        typeAlert: "error",
        messageAlert: "Não foi possível realizar a importação.",
      });

      setAlertIsVisible(true);
    }

    setIsLoading(false);
  }

  React.useEffect(() => {
    listAvas();
  }, []);

  return (
    <div className="row m-0">
      <div
        className={
          alertIsVisible === true ? "row m-0 area-alert" : "hide-area"
        }
      >
        <Alert
          type={alert.typeAlert}
          message={alert.messageAlert}
          closeAlert={() => setAlertIsVisible(false)}
        />
      </div>

      <div className="navigation">Configurações / Importação</div>

      <div className="p-3 pt-2">
        <div className="p-4 pb-2 row ">
          <h6 className="font-weight-bold p-0 mb-2">AVA: </h6>
          <select
            className="form-select"
            onChange={(event) => onChangeAVA(parseInt(event.target.value))}
          >
            {avas.map((ava) => (
              <option
                className="select-options"
                key={"ava" + ava.id_ava}
                value={ava.id_ava}
              >
                {ava.name_ava}
              </option>
            ))}
          </select>
        </div>

        <div className="p-4 pb-2 row ">
          <h6 className="font-weight-bold p-0 mb-2">Programa: </h6>
          <select
            className="form-select"
            onChange={(event) => {onChangeProgram(parseInt(event.target.value))}}
          >
            {programs.map((program) => (
              <option
                className="select-options"
                key={"program" + program.id_program}
                value={program.id_program}
              >
                {program.Modality.name_modality}
              </option>
            ))}
          </select>
        </div>

        <div className="p-4 pb-2 row ">
          <h6 className="font-weight-bold p-0 mb-2">Curso: </h6>
          <select
            className="form-select"
            onChange={(event) => setSelectedCourse(parseInt(event.target.value))}
          >
            {courses.map((course) => (
              <option
                className="select-options"
                key={"course" + course.id_course}
                value={course.code_ava_course}
              >
                {course.Modality.name_modality}
              </option>
            ))}
          </select>
        </div>

        <div className="p-4 pb-2 row ">
          <h6 className="font-weight-bold p-0 mb-2">CPF: </h6>
          <div className="input p-0">
            <IMaskInput
              type="text"
              maxLength={14}
              id="studentCpf"
              mask="000.000.000-00"
              placeholder="CPF do Aluno"
              className="form-control input search-input col-md-12 border-none"
              onAccept={(value) =>
                setSelectedCPF(value.replaceAll(".", "").replaceAll("-", ""))
              }
            />
          </div>
        </div>

        <div className="p-4 pt-5 row btn-area action-panel">
          <button className="btn btn-primary d-block" style={{width: '135px', marginRight: 'auto'}} onClick={() => importStudent()}>
            Importar
          </button>
        </div>
      </div>

      {isLoading ? <Loader /> : null}
    </div>
  );
};

export default ImportStudent;
